<template>
  <div>
    <el-tabs v-model="activeName" type="card" class="tabs" style="overflow: auto">
      <el-tab-pane :label="$t('AppManage.BlockingConfiguration.Equipment')" name="1">
        <el-radio-group style="margin-left: 150px; margin-top: 19px" v-model="permission.switch.use" @change="setRadio">
          <el-radio :label="1" @click.native.prevent="radios(1)">{{$t('public.Enable')}}</el-radio>
          <el-radio :label="0" @click.native.prevent="radios(0)">{{$t('AppManage.BlockingConfiguration.forbidden')}}</el-radio>
        </el-radio-group>
      </el-tab-pane>
      <div style="margin-top: 20px; ">
        <el-form :model="ruleForm" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          <el-form-item :label="$t('AppManage.BlockingConfiguration.ListOfDevices')" prop="udId">
            <el-input :onkeyup="ruleForm.udId= ruleForm.udId.replace(/[ ]/g,'')" :disabled="inputDisable" :placeholder="$t('AppManage.BlockingConfiguration.placeholder')" :rows="8" style="width: 400px" type="textarea"
              v-model="ruleForm.udId" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('AppManage.BlockingConfiguration.BlockingStrategy')">
            <el-checkbox :disabled="disabled1" v-model="permission.banned.collect"
              @change="fu(permission.banned.collect)" true-label="16" false-label="0">{{$t('AppManage.BlockingConfiguration.CollectionBehavior')}}</el-checkbox>
            <el-radio-group style="margin-left: 30px" v-model="permission.banned.action">
              <el-radio :disabled="disabled2" :label="4" @click.native.prevent="threeRadios(4)">{{$t('AppManage.BlockingConfiguration.AlarmMessage')}}</el-radio>
              <el-radio :disabled="disabled3" :label="8" @click.native.prevent="threeRadios(8)">{{$t('AppManage.BlockingConfiguration.ClearData')}}</el-radio>
              <el-radio :disabled="disabled4" :label="2" @click.native.prevent="threeRadios(2)">{{$t('AppManage.BlockingConfiguration.ExitApplication')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="submitForm('ruleForm')">{{$t('public.Save')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-tabs> 
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      activeName: "1", //默认显示当前页面
      inputDisable: true, //启用禁用 默认为true 禁用
      disabled1: true, //采集行为 默认为true 禁用
      disabled2: true, //告警提示 默认为true 禁用
      disabled3: true, //清除数据 默认为true 禁用
      disabled4: true, //退出应用 默认为true 禁用 
      permission: {//启用、禁用   （默认为禁用）
        switch: {
          use: 0,
        },
        banned: {
          collect: "16",
          action: "4",
        },
      }, 
      ruleForm: {//多个 UDID
        udId: "",
      },
      rules: {
        udId: [
          { required: true, message: "请输入UDID", trigger: "blur" }
        ],
      },
    };
  },
  mounted() {
    this.search(); //刚进去查询
  },
  methods: {
    // 判断禁用
    setRadio(val) {
      if (val == 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    radios(value) {
      this.permission.switch.use = value;
      // this.permission.switch.use = value == this.permission.switch.use ? "" : value;
      if (this.permission.switch.use === 0) {
        this.$refs.ruleForm.clearValidate();
        this.inputDisable = true;

        this.disabled1 = true;
        this.disabled2 = true;
        this.disabled3 = true;
        this.disabled4 = true;
      } else if (this.permission.switch.use === 1) {
        this.inputDisable = false;
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = false;
        this.disabled4 = false;
      }
    },
    fu(value) {
      console.log(value, "valie");
      this.permission.banned.collect = value;
    },
    threeRadios(value) {
      console.log(value, "threeRadios");
      this.permission.banned.action =
        value == this.permission.banned.action ? "" : value;
    },
    // 查询
    async search() {
      const res = await this.$axios.post(
        "/httpServe/ban/device/udid/selectOne",
        {},
        true
      );
      this.permission.switch.use = res.data.use;
      this.ruleForm.udId = res.data.udid.toString().replaceAll(",", "\n");
      this.permission.banned.collect = res.data.collect + "";
      this.permission.banned.action = res.data.action;
      if (this.permission.switch.use === 0) {
        this.inputDisable = true;
        this.disabled1 = true;
        this.disabled2 = true;
        this.disabled3 = true;
        this.disabled4 = true;
      } else if (this.permission.switch.use === 1) {
        this.inputDisable = false;
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = false;
        this.disabled4 = false;
      }
    },
   
    async save() { // 列表
      if (this.permission.banned.collect == "0" && this.permission.banned.action == "") {
        this.$message({
          message: this.$t('AppManage.BlockingConfiguration.DisablePolicySelectAtLeastOneOption'),
          type: "error", //error
          offset: 100,
        });
        return false;
      }
      var arr = this.ruleForm.udId.split("\n");
      // 删除空字符串
      for (var i = arr.length - 1; i >= 0; i--) {
        if (arr[i] == "") {
          arr.splice(i, 1);
        }
      }
      console.log(arr ,'arr',typeof(arr),arr.length)

      var param = {
        use: this.permission.switch.use,
        udid: arr,
        collect: this.permission.banned.collect,
        action: this.permission.banned.action,
      }; 
      if (arr.length > 200) {
        this.$message({
          message: this.$t('AppManage.BlockingConfiguration.maximumUDID'),
          type: "error",  
          offset: 100,
        });
      } else if(arr.length == 0  && this.permission.switch.use === 1){
        this.$message({
          message: this.$t('AppManage.BlockingConfiguration.pleaseUDID'),
          type: "error",  
          offset: 100,
        });
      } 
       else { 
        const res = await this.$axios.post(
          "/httpServe/ban/device/udid/update",
          param,
          true
        ); 
        if (res.code == 200) {
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: "success", 
            offset: 100,
          });
          this.search();
        }
      }
    },
    
    submitForm(ruleForm) {// 保存
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  height: 580px;
  border: 1px solid #dddd;
  margin-left: 10px;
  margin-top: 30px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  border-radius: 5px;
}
</style>